

.start {
    display: flex;
    flex-direction: column;
    background: url("../../assets/images/bg-image-leaves.webp");
    background-attachment: fixed;
    background-position: center;
    justify-content: center;
    background-size: cover;
    color: $light;
    padding: 2em;
    min-height: 100dvh;
    
    @supports (-webkit-touch-callout: none) {
        background-attachment: scroll;
    }

    @include breakpoint("s") {
        min-height: 70dvh;
    }

    @include breakpoint("m") {
        min-height: calc(100vh - 170px);
    }

    &__welcome {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 0;

        @include breakpoint("s") {
            width: 75%;
        }
        @include breakpoint("l") {
            width: 40%;
            margin: 1em;
        }
    }

    &__button-termin {
        background-color: $red;
        color: $light;
        border: none;
        width: fit-content;
        text-align: center;

        .book-appoi {
            display: block;
            padding: 1em;
            width: 7em;
            text-decoration: none;
            color: $light;
            border-radius: 8px;
            
            @include breakpoint("m") {
                &:hover {
                    background-color: $light;
                    color: $red;
                }
            }
        }
    }
}