.artist {
    color: $dark;
    display: flex;
    position: relative;
    justify-content: center;
    margin: 0 0.25em;
    scroll-snap-align: center;

    @include breakpoint("s") {
        margin: 0 0.75em;
    }

    @include breakpoint("m") {
        margin: 0 1em;
    }

    @include breakpoint("l") {
        margin: 0;
        height: 21em;
    }

    &-wrapper_collapsed {
        width: 15em;
        height: 22em;
        color: $light;
        margin: 0;
        border-radius: 8px;
        overflow: hidden;

        @include breakpoint("s") {
            width: 20em;
            height: 30em;
        }

        @include breakpoint("l") {
            border-radius: 0;
            padding: 0;
            width: 25em;
            height: auto;
        }
    }

    &__image-wrapper {
        width: fit-content;
        margin: 1em;
    }

    &__link {
        & > p {
            display: inline;
        }
        color: $light;
        
        @include breakpoint("l") {
            & > div > p {
                display: none;
            }

            &:hover > img {
                filter: blur(2px);
                transition: 0.5s;
            }
    
            &:hover > div > p {
                display: inline;
            }
        }
    }

    &__portrait_collapsed {
        width: 100%;
    }

    &-wrapper_extended {
        position: fixed;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        background-color: $dark;
        height: 100vh;
        width: 100vw;
        z-index: 2;
    }

    &-wrapper_extended--hidden {
        display: none;
    }

    &__button {
        margin: 1em;
        background-color: $light;
        border: none;
        cursor:pointer;
        &:hover {
            opacity: 0.9;
        }
    }

    &__portrait-wrapper {
        height: 40vh;
    }

    &__portrait_extended {
        height: 27vh;
        margin: 1em;

        @include breakpoint("s") {
            height: 30vh;
        }
    }

    &__text {
        color: $light;

        &_extended {
            margin: 0.5em 0;

            @include breakpoint("s") {
                margin: 1em 0;
            }
        }

        &_collapsed {
            width: 100%;
            text-align: center;
        }
    }

    &__text-wrapper_collapsed {
        display: none;
        position: absolute;
        top: 65%;
        left: 50%;
        width: 100%;
        transform: translate(-50%, -10%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: rgba($color: $dark, $alpha: 0.4);
    }

    &__text_collapsed {
        text-align: center;
    }

    &__text-wrapper_extended {
        padding: 1em;
        width: 40%;
    }

    &__info-wrapper {
        display: flex;
        justify-content: center;
        width: fit-content;
        max-height: 47.5vh;
        margin: auto;
    }

    &__gallery {
        align-items: flex-end;
    }

    &__image-wrapper {
        display: flex;
        margin: auto;
        max-width: 90%;
        height: 45vh;
        flex-wrap: nowrap;
        overflow-x: scroll;
        scroll-snap-type: x mandatory;
    }

    &__image {
        margin: 0 1em;
        height: 40vh;
        object-fit: cover;
        border-radius: 10px;
        scroll-snap-align: center;
    }
}