
.headerDesk {
    display: flex;
    background-color: $light;
    padding: 0.75em;

    &__image-wrapper {
        margin: auto;
    }

    &__image {
        width: 6em;
    }

    &__nav {
        margin: auto;

        .headerDesk__list {
            display: flex;
            flex-direction: row;

            .headerDesk__item {
                width: fit-content;
                list-style: none;
                padding: 0 1em;
                margin: auto;

                .headerDesk__link {
                    color: $dark;
                    text-decoration: none;

                    &:hover {
                        color: $red;
                    }
                }
            }
        }
    }

}

.headerMob {
    background-color: $light;
    position: absolute;
    width: 100%;


    &__nav {
        margin: auto;
        display: grid;
        grid-template-columns: 15% 70% 15%;
        min-height: 2em;
    }

    &__button {
        margin: 1em auto;
        grid-column-start: 1;
        grid-column-end: 1;
        border: none;
        height: 2em;
        align-self: center;

        &--collapsed {
            align-self: center;
        }
    }

    &__image {
        width: 2em;
        grid-column-start: 2;
        margin: auto;
        align-self: center;
    }

    &__list {
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 1;
        padding: 0;
    }

    &__item {
        width: fit-content;
        list-style: none;
        padding: 1em 0;
        margin: auto;
    }

    &__link {
        color: $dark;
        text-decoration: none;
    }
}