.opening {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: $light;
    color: $dark;
    align-items: center;
    justify-content: center;
    min-height: 15em;
    padding: 1em 0;

    @include breakpoint("l") {
        width: 100%;
        min-height: 25em;
    }

    &__time {
        padding: 0 2em;
        margin-bottom: 0;
        text-align: center;
        min-width: 5em;

        @include breakpoint("s") {
            min-width: 10em;
        }

        @include breakpoint("m") {
            min-width: 16em;
        }
    }

    &__wrapper {
        display: flex;
        flex-direction: row;
    }
}