.service {
    color: $light;
    padding: 2em;
    margin: 5em 0;
    
    @include breakpoint("l") {
        color: $light;
    }

    &__header {
        display: flex;
        justify-content: center;
        width: fit-content;
    }

    &-wrapper {
        display: flex;
        justify-content: center;
        flex-direction: column;
        max-width: 55em;
        margin: 2em auto;

        @include breakpoint("l") {
            flex-direction: row;
        }
    }

    &__article_piercing {
        width: 100%;
    }

    &__article_tattoo {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;

        @include breakpoint("m") {
            flex-direction: row;
            justify-content: flex-start;
        }
    }

    &__text {
        display: flex;
        flex-direction: column;
        
        @include breakpoint("l") {
            display: flex;
            flex-direction: column;
        }
    }

    &__img {
        display: flex;
        flex-direction: column;
        object-fit: contain;
        width: 90%;
        
        @include breakpoint("m") {
            width: 50%;
            margin-left: 2%;
        }
    }
}