.about {
    background-color: $light;
    color: $dark;
    padding: 2em;

    &-wrapper {
        max-width: 55em;
        margin: 2em auto;
    }

    &__header {
        width: fit-content;
        margin: 0 auto;
        margin-bottom: 1.5em;
        text-align: center;
    }
}