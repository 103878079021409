
@import "~react-image-gallery/styles/scss/image-gallery.scss";

.gallery {
    display: flex;
    flex-direction: column;
    background-color: $dark;
    color: $light;
    width: 100%;
    padding-top: 5em;

    &__header-wrapper {
        display: flex;
    }
}

.image-gallery {
    &-content
    &-slide
    &-image {
        height: 35em;
        margin-top: 3em; 
        margin-top: 0;
        
        @include breakpoint("m") { 
            margin-top: 3em;
        }
    }

    &-play-button {
        display: none;
    }

    &-right-nav, &-left-nav {
        display: none;
        z-index: 1;

        @include breakpoint("l") {
            display: inline;
            color: $light;
            filter: none;
        
            &:hover {
                color: $red;
            }
        }
    }

    &-right-nav {
        right: 10%;
    }

    &-left-nav {
        left: 10%;
    }

    &-fullscreen-button {
        display: none;
    }

    &-thumbnail {
        &.active, &:hover {
            border: 3px solid $red;
        }
    }
}
