.scroll-wrapper {
    position: fixed;
    z-index: 1;
    bottom: 0.75em;
    left: 0.75em;
}

.scroll__button {
    width: fit-content;
    height: fit-content;
    border-width: 0;
    border-radius: 0.5em;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2);
    cursor:pointer;
    &:hover {
        opacity: 0.9;
    }
}

