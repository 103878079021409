
.appointment {
    background: url("../../assets/images/appointment-image00.webp");
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    background-color: $dark;
    text-align: center;
    
    @supports (-webkit-touch-callout: none) {
        background-attachment: scroll;
    }

    &__background {
        background-color: rgba($color: $dark, $alpha: 0.6);
        color: $light;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &__header-wrapper {
        padding-top: 2em;
    }

    &__header {
        width: fit-content;
        margin: 1em auto;
        margin-top: 0;
    }

    &-wrapper {
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: 1em;
        margin: 0 auto;

        @include breakpoint("m") {
            margin: 0;
        }
    }

    &__sect {
        margin: 1em;

        @include breakpoint("m") {
            margin: 3em;
        }
    }

    &__text {
        text-align: center;
    }

    &__btn {
        background-color: $red;
        color: $light;
        border: none;
        width: fit-content;
        height: 3em;
        text-align: center;
    }
    
    &__anchor {
        width: 7em;
        text-decoration: none;
        color: $light;
        border-radius: 8px;
    }

    &__button-wrapper {
        width: fit-content;
        margin: auto;
    }

    &__iframe {
        width: 85dvw;
        height: 35rem;
        margin: auto;

        @include breakpoint("s") {
            width: 60dvw;
        }

        @include breakpoint("m") {
            width: 40dvw;
        }
        
        @include breakpoint("l") {
            width: 40dvw;
        }
        
        @include breakpoint("xl") {
            width: 40dvw;
        }

        &-wrapper {
            text-align: center;
            margin-bottom: 3rem;
        }
    }
}