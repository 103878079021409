.team {
    padding: 5em 0;
    background: url("../../assets/images/bg-image-leaves.webp");
    background-size: cover;
    background-attachment: fixed;
    
    @supports (-webkit-touch-callout: none) {
        background-attachment: scroll;
    }

    &__header {
        color: $light;
        width: fit-content;
        text-align: center;
    }

    &-wrapper {
        margin: 0 auto;
        margin-top: 5em;
        display: flex;
        padding: 0 1.75em;
        overflow-x: scroll;
        scroll-snap-type: x mandatory;

        @include breakpoint("l") {
            display: grid;
            grid-template-columns: repeat(5, 20%);
            overflow: hidden;
            height: fit-content;
            justify-content: center;
            width: 70em;
        }
    }
}