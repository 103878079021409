.footer {
    background-color: $dark;
    color: $light;
    height: fit-content;
    padding: 2em;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;

    @include breakpoint("s") {
        flex-direction: row;
    }

    &-wrapper {
        max-width: 31em;
        display: flex;
        flex-wrap: wrap;
    }

    &__imprint-wrapper {
        display: flex;
        flex-direction: column;
        text-decoration: none;
        text-align: left;
        margin: 0 1.8em;
        width: 15em;
    }

    &__collapse {
        display: flex;
        margin: auto;
        @include breakpoint("s") {
            display: inline;
        }
    }

    &__phone {
        margin: 0 1.8em;
        text-align: center;
        width: 15em;
    }

    &__imprint_hidden {
        display: none;
    }

    &__imprint {
        display: flex;
        flex-direction: row;
        margin: auto;
    }

    &__list {
        list-style: none;
        padding: 0;
        text-align: center;

        @include breakpoint("s") {
            text-align: left;
        }
    }

    &__anchor {
        text-decoration: inherit;
        color: inherit;   
    }

    &__button {
        background-color: transparent;
        color: $light;
        border: none;
        padding: 0;
        display: flex;
        width: fit-content;
        height: fit-content;
        text-align: center;
        margin: auto;
        cursor:pointer;
        @include breakpoint("s") {
            margin: 0;
        }
    }

    &__icon-wrapper {
        display: flex;
    }

    &__icon {
        filter: invert(1);
    }
}