.rounded-cor {
    border-radius: 8px;
}

.header {
    text-transform: uppercase;
    font-family: $specialFont;

    &--large {
        width: fit-content;
        font-size: 2em;

        @include breakpoint("s") {
            font-size: 2.5em;
        }

        @include breakpoint("m") {
            font-size: 3.5em;
        }
    }

    &--medium {
        font-size: 1.25em;
        margin: 1em auto;
        margin-top: 0;
        border-bottom: 1px solid;

        @include breakpoint("l") {
            font-size: 2em;
        }
    }

    &--small {
        font-size: 1em;

        @include breakpoint("l") {
            font-size: 1.5em;
        }
    }
}

.text {
    font-size: 1em;
    line-height: 1.3;

    &--large {
        @include breakpoint("l") {
            font-size: 1.5em;
        }
    }
}

.btn {
    &--small {
        font-size: 1em;
        margin: 1em;
    }
}

.icon--large {
    width: 3em;
    height: 3em;

    @include breakpoint("l") {
        width: 3.5em;
        height: 3.5em;
    }
}

.icon--medium {
    width: 1.5em;
    height: 1.5em;
}

.input--standard {
    display: flex;
    flex-direction: column;
    width: 70%;
    margin: auto;
    margin-bottom: 2em;
}

html {
    scroll-behavior: smooth;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;
}

html::-webkit-scrollbar {
    display: none;
}

body {
    margin: 0;
    font-family: "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: $dark;
}